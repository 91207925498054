import { resources } from './locales';

export const i18n = {
    // This is the list of languages your application supports
    supportedLngs: ['en', 'nl', 'fr', 'de'],
    // This is the language you want to use in case
    // if the user language is not in the supportedLngs
    fallbackLng: 'en',
    lng: 'en',
    // The default namespace of i18next is "translation", but you can customize it here
    defaultNS: 'shop',
    nsSeparator: false,
    keySeparator: false,
    resources,
} as const;
